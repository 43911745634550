import React from "react";
import { Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AdminProvider } from "./contexts/admin-context";

import Account from "./components/Account";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import Logout from "./components/Logout";
import Login from "./components/Login";
import Layout from "./components/Layout";

import ProductsAdd from "./components/Products/ProductsAdd";
import ProductsUpdate from "./components/Products/ProductsUpdate";
import ProductsList from "./components/Products/ProductsList";
import OrderDetailPage from "./components/Orders/OrderDetailPage";

import "antd/dist/antd.css";
import "./assets/styles/styles.scss";
import OrdersList from "./components/Orders/OrdersList";

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AdminProvider>
        <Switch>
          <Route path={`/login`} exact={true}>
            <Login />
          </Route>
          <Route path={`/`}>
            <PrivateRoute>
              <Layout>
                <Route path={`/`} exact={true}>
                  <Dashboard />
                </Route>
                <Route path={`/products/add`} exact={true}>
                  <ProductsAdd />
                </Route>
                <Route path={`/products/:id/update`} exact={true}>
                  <ProductsUpdate />
                </Route>
                <Route path={`/products`} exact={true}>
                  <ProductsList />
                </Route>
                <Route path={`/statistics`}>
                  <h1>Statistics</h1>
                </Route>
                <Route path={`/users`}>
                  <h1>Users</h1>
                </Route>
                <Route path={`/messages`}>
                  <h1>Messages</h1>
                </Route>
                <Route path={`/my-account`}>
                  <Account />
                </Route>
                <Route path={`/sales`}>
                  <OrdersList />
                </Route>
                <Route path={`/orders/:id`} exact>
                  <OrderDetailPage />
                </Route>
                <Route path={`/logout`}>
                  <Logout />
                </Route>
              </Layout>
            </PrivateRoute>
          </Route>
        </Switch>
      </AdminProvider>
    </QueryClientProvider>
  );
};

export default App;
