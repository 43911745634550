import { api } from "./";

export const getOrderDetails = async (id) => {
  const response = await api.get(`/order/${id}/details`);

  return response.data;
};

export const markOrderAsShipped = async (id) => {
  await api.patch(`/order/${id}/shipped`);
};
