import React, { useEffect, useState } from "react";
import { Table } from "antd";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";

const OrdersList = () => {
  const [orders, setOrders] = useState([]);

  const history = useHistory();

  const getOrders = async () => {
    try {
      const response = await axios.get("/order");
      setOrders(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOrders();
    })();
  }, []);

  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format("MMMM Do, YYYY [at] HH:mm:ss"),
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: ({ firstName, lastName }) => `${firstName} ${lastName}`,
    },
  ];

  return (
    <>
      <section>
        <h1>Orders</h1>
      </section>

      <section>
        <Table
          dataSource={orders}
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50", "100"],
            hideOnSinglePage: true,
          }}
          onRow={(record) => ({
            onClick: () => {
              history.push(`/orders/${record.id}`);
            },
          })}
        />
      </section>
    </>
  );
};

export default OrdersList;
