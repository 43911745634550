import React, { useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import useLogin from "../hooks/useLogin";
import axios from "axios";

const PrivateRoute = ({ children }) => {
  const match = useRouteMatch();

  const { user, login } = useLogin();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const loginFromLocalStorage = async () => {
      if (!user) {
        // are we here?
        let loggedIn = false;

        // see if we can log in
        const { data } = await axios.get("/user/profile");

        if (data && data.email) {
          loggedIn = true;
          login({
            username: data.email,
            role: data.role,
          });
        }

        const redirectTo = loggedIn ? match.path : "/login";
        setRedirect(redirectTo);
      }
    };
    loginFromLocalStorage();
  }, [user, login, match.path]);

  if (user) {
    return <>{children}</>;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return "Loading...";
};

export default PrivateRoute;
