import React from 'react';
import Navigation from './Navigation';
import '../assets/styles/styles.scss'

const Layout = ({ children }) => {

  return (
    <div className="layout">
      <Navigation/>
      <main>
        {children}
      </main>
    </div>
  );
};

export default Layout;
