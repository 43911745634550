import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, DatePicker, InputNumber, Tooltip, Upload, Modal, Switch, Select, Checkbox, Popconfirm, message } from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import slugify from "slugify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProductState } from "../../microservices/products";
import { updateProductToSaleStatus } from "../../requests/product";
const moment = require("moment");

const { RangePicker } = DatePicker;
const { REACT_APP_PUBLIC_API_URL } = process.env;

const ProductsForm = (props) => {
  const { onSubmit, product } = props;
  const token = localStorage.getItem("token");
  const isNewProduct = !product || !product.title;
  const productType = product?.type || "physical";

  const [form] = Form.useForm();
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(productType === "physical");
  const [deliveryOptions, setDeliveryOptions] = useState([
    { name: "pickup", checked: false, price: 0 },
    { name: "smartpost", checked: false, price: 0 },
    { name: "delivery", checked: false, price: 0 },
  ]);
  const [productState, setProductState] = useState(product?.status || "preview");

  const queryClient = useQueryClient();

  const updateProductToSaleStatusMutation = useMutation({
    mutationFn: updateProductToSaleStatus,
    onSuccess: () => {
      message.success({
        className: "message__success",
        content: `Product up for sale`,
        duration: 8,
      });

      queryClient.invalidateQueries(["product", product.id]);
    },
    onError: ({ response: { data } }) => {
      message.error({
        className: "message__error",
        content: data.message,
        duration: 8,
      });
    },
  });

  useEffect(() => {
    if (product && product.productDeliveryOptions) {
      const newDeliveryData = [];

      for (const option of deliveryOptions) {
        const existingOption = product.productDeliveryOptions.find((row) => row.type === option.name);

        if (existingOption) {
          newDeliveryData.push({ ...option, price: existingOption.price, checked: true });

          continue;
        }

        newDeliveryData.push(option);
      }

      setDeliveryOptions(newDeliveryData);
    }
  }, []);

  // useEffect(() => {
  //   form.deliveryOptions = deliveryOptions.filter((option) => option.checked).map((option) =>  { return {
  //     id: option.id,
  //     price: option.price}
  //   });
  // }, [deliveryOptions])

  const handleCancel = () => {
    setState((oldState) => ({
      ...oldState,
      previewVisible: false,
    }));
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState((oldState) => ({
      ...oldState,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    }));
  };

  const handleChange = (data) => {
    const { fileList } = data;

    setState((oldState) => ({
      ...oldState,
      fileList,
    }));
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        ...product,
        productType,
        productDates: [moment(product.dateStart), moment(product.dateEnd)],
      });

      // filelist
      const images = product.images.map((image) => {
        return {
          uid: image.id,
          name: image.name,
          status: "done",
          url: image.name,
        };
      });
      setState((oldState) => ({
        ...oldState,
        fileList: images,
      }));
    }
  }, []);

  const getBase64 = (fileURL) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileURL);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onFinish = (values) => {
    const parsedDeliveryOptions = deliveryOptions
      .filter((option) => option.checked)
      .map((option) => {
        return {
          name: option.name,
          price: option.price,
        };
      });
    const images = state.fileList.filter((image) => image.status === "done").map((image) => (image.response ? image.response.id : image.uid));
    onSubmit({ ...values, images, deliveryOptions: parsedDeliveryOptions });
  };

  const handleFormChange = () => {
    // console.log('form changed');
    // console.log(form.fields)
  };

  const updateForm = (event) => {
    if (isNewProduct) {
      form.setFieldsValue({ slug: slugify(event.target.value, { lower: true }) });
    }
  };

  const handleDeliveryOptionChange = (event) => {
    const newDeliveryOptions = deliveryOptions.map((option) => {
      const newOption = { ...option };

      if (newOption.name === event.target.name) {
        newOption.checked = event.target.checked;
      }
      return newOption;
    });

    setDeliveryOptions(newDeliveryOptions);
  };

  const handleDeliveryOptionPrizeChange = (name) => (value) => {
    const newDeliveryOptions = deliveryOptions.map((option) => {
      const newOption = { ...option };

      if (newOption.name === name) {
        newOption.price = value;
      }
      return newOption;
    });

    setDeliveryOptions(newDeliveryOptions);
  };

  const updateProductStateAdmin = async (newState) => {
    const result = await updateProductState(product.id, newState);
    if (result.name) {
      setProductState(result.name);
    }
  };

  const renderStateButton = () => {
    let newState;
    let buttonText;

    if (productState === "READY_FOR_SALE") {
      newState = "on_sale";
      buttonText = "Move to sale";
    }

    if (productState === "ON_SALE") {
      newState = "ended";
      buttonText = "End sale";
    }

    if (newState && buttonText) {
      return (
        <Row type="flex" gutter={8}>
          <button onClick={() => updateProductStateAdmin(newState)}>{buttonText}</button>
        </Row>
      );
    }
  };

  return (
    <>
      <Row type="flex" gutter={8}>
        <Col span={4}>Current state</Col>
        <Col span={10}>{productState}</Col>
      </Row>
      {renderStateButton()}
      <Row type="flex" gutter={8}>
        <Col span={4}>&nbsp;</Col>
      </Row>
      <Form form={form} onFinish={onFinish} onChange={(event) => handleFormChange(event)}>
        <Row type="flex" gutter={8}>
          <Col span={14}>
            <Row>
              <Col>
                <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                  <Input onChange={(event) => updateForm(event)} />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Name of the product">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
                  <Input disabled={!isNewProduct} />
                </Form.Item>
              </Col>
              <Col>
                {/*<Tooltip placement="topLeft" title="Slug of the product. Has to be unique">*/}
                {/*  <InfoCircleOutlined />*/}
                {/*</Tooltip>*/}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Subtitle" name="subtitle" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Subtitle of the product">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Type" name="productType" rules={[{ required: true }]}>
                  <Select onChange={(event) => setShowDeliveryOptions(event === "physical")}>
                    <Select.Option value="physical">Physical</Select.Option>
                    <Select.Option value="virtual">Virtual</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Is it a physical or a virtual product?">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Banner" name="banner">
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Banner text to be shown above the product image">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Short Description" name="description">
                  <Input.TextArea rows={8} cols={24} />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Short description of the product, will be shown on the homepage">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Long Description" name="longDescription">
                  <Input.TextArea rows={8} cols={24} />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Longer description of the product, will be shown on the product detail page">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Deal Terms" name="tc">
                  <Input.TextArea rows={8} cols={24} />
                </Form.Item>
              </Col>
              <Col>
                <Tooltip placement="topLeft" title="Terms for this deal, will be shown on the product detail page">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Featured" name="featured" valuePropName="checked">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked={product.featured} />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="RRP" name="rrp" rules={[{ required: true }]}>
                  <InputNumber
                    formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    min="0"
                    max="100000"
                    step="0.01"
                    stringMode={true}
                    className="price-input"
                    precision={2}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Start Price" name="priceStart" rules={[{ required: true }]}>
                  <InputNumber
                    formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    min="0"
                    max="100000"
                    step="10"
                    stringMode={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="End Price" name="priceEnd" rules={[{ required: true }]}>
                  <InputNumber
                    formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    min="0"
                    max="100000"
                    step="10"
                    stringMode={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Margin" name="margin" rules={[{ required: true }]}>
                  <InputNumber formatter={(value) => `${value} %`} min="0" max="500" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Quantity" name="quantity">
                  <InputNumber min="0" max="100000" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="First drop range" name="dropRangeStart">
                  <InputNumber formatter={(value) => `${value} %`} min="0" max="100" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="First drop value" name="dropValueStart">
                  <InputNumber formatter={(value) => `${value} %`} min="0" max="100" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Last drop range" name="dropRangeEnd">
                  <InputNumber formatter={(value) => `${value} %`} min="0" max="100" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Last drop value" name="dropValueEnd">
                  <InputNumber formatter={(value) => `${value} %`} min="0" max="100" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Predicted droppers" name="droppersPredicted" rules={[{ required: true, min: 1, type: "number" }]}>
                  <InputNumber min="0" max="10000" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Manually added droppers" name="droppersManual">
                  <InputNumber min="0" max="10000" step="1" />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Item label="Unit costs" name="unitCosts" rules={[{ required: true }]}>
                  <InputNumber
                    formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    min="0"
                    max="100000"
                    step="10"
                    stringMode={true}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              <Col>
                <Form.Item label="Start and end date" name="productDates" rules={[{ required: true }]}>
                  <RangePicker showTime={{ format: "HH:mm" }} format="YYYY-MM-DD HH:mm" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Col>
                <Upload
                  action={`${REACT_APP_PUBLIC_API_URL}/product/image`}
                  listType="picture-card"
                  fileList={state.fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  headers={{ Authorization: `Bearer ${token}` }}
                >
                  {state.fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal visible={state.previewVisible} title={state.previewTitle} footer={null} onCancel={handleCancel}>
                  <img alt="example" style={{ width: "100%" }} src={state.previewImage} />
                </Modal>
              </Col>
            </Row>

            {showDeliveryOptions && (
              <>
                <Row>
                  <Col>
                    <h2>Delivery options</h2>
                  </Col>
                </Row>
                {deliveryOptions.map((deliveryOption) => {
                  return (
                    <Row key={deliveryOption.name}>
                      <Col span={10}>
                        <Checkbox name={deliveryOption.name} checked={deliveryOption.checked} onChange={handleDeliveryOptionChange}>
                          {deliveryOption.name}
                        </Checkbox>
                      </Col>
                      <Col>
                        <InputNumber
                          formatter={(value) => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          min="0"
                          max="200"
                          step=".01"
                          stringMode={true}
                          value={deliveryOption.price}
                          name={deliveryOption.name}
                          onChange={handleDeliveryOptionPrizeChange(deliveryOption.name)}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}
          </Col>
        </Row>

        <Row gutter={8}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isNewProduct ? "Add Product" : "Update Product"}
            </Button>

            {product.status === "ready_for_sale" && (
              <Popconfirm
                title="Are you sure you want to put item for sale?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => updateProductToSaleStatusMutation.mutate(product.id)}
              >
                <Button type="primary" htmlType="submit" style={{ marginLeft: 40 }} loadin={updateProductToSaleStatusMutation.isPending}>
                  Put item for sale
                </Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default ProductsForm;
