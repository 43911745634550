import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import useLogin from "../hooks/useLogin";
import logoWhite from "../assets/images/logo_white.svg";

const Login = () => {
  const { login, user, logout } = useLogin();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    logout();
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    setErrors(false);

    const { data } = await axios.post("/auth/login", { email, password });

    if (data.error) {
      setErrors([].concat(data.message));
    } else {
      // we have an user
      login(data);
    }
  };

  if (user) {
    return <Redirect to={`/`} />;
  }

  return (
    <div className="login">
      <div className="login__header">
        <div className="login__logo">
          <img src={logoWhite} alt="Logo" />
        </div>
      </div>
      <div className="login__container">
        <form className="login__form">
          <div className="login__field">
            <input
              type="email"
              name="email"
              className="input login--input"
              placeholder=" "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email" className="label login--label">
              Email
            </label>
          </div>

          <div className="login__field">
            <input
              type="password"
              name="password"
              className="input login--input"
              placeholder=" "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="password" className="label login--label">
              Password
            </label>
          </div>

          {errors && (
            <div className="login__error">
              <p>Error: </p>
              {errors.map((error) => {
                return <p key={error}>- {error}</p>;
              })}
            </div>
          )}

          <button className="login__button" onClick={submitForm}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
