import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";
import ProductsForm from "./ProductsForm";
import { Redirect } from "react-router-dom";

const defaultProduct = {
  dropRangeStart: 20,
  dropRangeEnd: 5,
  dropValueStart: 35,
  dropValueEnd: 15,
  images: [],
};

const ProductsAdd = () => {
  const [viewProductId, setViewProductId] = useState(false);

  if (viewProductId) {
    return <Redirect push to={`/products/${viewProductId}/update`} />;
  }

  const onSubmit = async (values) => {
    const payload = { ...values };

    // check for start and end date
    payload.dateStart = payload.productDates[0];
    payload.dateEnd = payload.productDates[1];

    payload.quantity = payload.quantity || 0;
    payload.droppersPredicted = payload.droppersPredicted || 0;
    payload.droppersManual = payload.droppersManual || 0;

    // submit request to backend
    try {
      const response = await axios.post("/product", payload);

      if (response.status >= 400) {
        const errors = (
          <>
            {[].concat(response.data.message).map((error, index) => (
              <span key={index}>
                {`${error}`}
                <br />
              </span>
            ))}
          </>
        );

        message.error({
          className: "message__error",
          content: errors,
          duration: 8,
        });
      } else {
        message.success({
          className: "message__success",
          content: `Successfully added product: ${response.data.title}`,
          duration: 8,
        });

        setViewProductId(response.data.product.id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <section>
        <h1>Add new product</h1>
      </section>

      <ProductsForm onSubmit={onSubmit} product={defaultProduct} />
    </>
  );
};

export default ProductsAdd;
