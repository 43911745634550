import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams, Link } from "react-router-dom";
import { Button, Descriptions, message } from "antd";

import { getOrderDetails, markOrderAsShipped } from "../../requests/order";

const OrderDetailPage = () => {
  const { id } = useParams();

  const { data, refetch } = useQuery({ queryKey: ["order-details", id], queryFn: () => getOrderDetails(id) });

  const markOrderAsShippedMutation = useMutation({
    mutationFn: markOrderAsShipped,
    onSuccess: () => {
      refetch();

      message.success({
        className: "message__success",
        content: `Order marked as shipped`,
        duration: 8,
      });
    },
    onError: ({ response: { data } }) => {
      message.error({
        className: "message__error",
        content: data.message,
        duration: 8,
      });
    },
  });

  if (!data) {
    return null;
  }

  const labelStyle = { fontWeight: 600 };

  return (
    <>
      <Descriptions title="User info" layout="vertical">
        <Descriptions.Item label="First name" labelStyle={labelStyle}>
          {data.user.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last name" labelStyle={labelStyle}>
          {data.user.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Email" labelStyle={labelStyle}>
          {data.user.email}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Order info" layout="vertical" style={{ paddingTop: 40 }}>
        <Descriptions.Item label="Total" labelStyle={labelStyle}>
          {data.order.total}€
        </Descriptions.Item>
        <Descriptions.Item label="Single price" labelStyle={labelStyle}>
          {data.orderLine.single}€
        </Descriptions.Item>
        <Descriptions.Item label="Quantity" labelStyle={labelStyle}>
          {data.orderLine.quantity}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery option" labelStyle={labelStyle}>
          {data.order.deliveryOption}
        </Descriptions.Item>
        <Descriptions.Item label="Status" labelStyle={labelStyle}>
          {data.order.status}
        </Descriptions.Item>
        <Descriptions.Item label="Payment status" labelStyle={labelStyle}>
          {data.order.paymentStatus}
        </Descriptions.Item>
        <Descriptions.Item label="Phone number" labelStyle={labelStyle}>
          {data.order.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Reference" labelStyle={labelStyle}>
          {data.order.reference}
        </Descriptions.Item>
        <Descriptions.Item label="Payment reference" labelStyle={labelStyle}>
          {data.order.paymentReference}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Product info" layout="vertical" style={{ paddingTop: 40 }}>
        <Descriptions.Item label="Product name" labelStyle={labelStyle}>
          <Link to={`/products/${data.product.id}/update`}>{data.product.title}</Link>
        </Descriptions.Item>
      </Descriptions>

      {data.parcelTerminal && (
        <Descriptions title="Parcel terminal info" layout="vertical" style={{ paddingTop: 40 }}>
          <Descriptions.Item label="ID" labelStyle={labelStyle}>
            {data.parcelTerminal.id}
          </Descriptions.Item>

          <Descriptions.Item label="Name" labelStyle={labelStyle}>
            {data.parcelTerminal.name}
          </Descriptions.Item>
        </Descriptions>
      )}

      {data.address && (
        <Descriptions title="Address info" layout="vertical" style={{ paddingTop: 40 }}>
          <Descriptions.Item label="First name" labelStyle={labelStyle}>
            {data.address.firstName}
          </Descriptions.Item>

          <Descriptions.Item label="Last name" labelStyle={labelStyle}>
            {data.address.lastName}
          </Descriptions.Item>

          <Descriptions.Item label="Address" labelStyle={labelStyle}>
            {data.address.address}
          </Descriptions.Item>

          <Descriptions.Item label="City" labelStyle={labelStyle}>
            {data.address.city}
          </Descriptions.Item>

          <Descriptions.Item label="Country" labelStyle={labelStyle}>
            {data.address.country}
          </Descriptions.Item>

          <Descriptions.Item label="ZIP" labelStyle={labelStyle}>
            {data.address.zip}
          </Descriptions.Item>
        </Descriptions>
      )}

      {data.order.status === "payment_success" && (
        <Button
          type="primary"
          style={{ marginTop: 40 }}
          loading={markOrderAsShippedMutation.isPending}
          onClick={() => markOrderAsShippedMutation.mutate(id)}
        >
          Mark as shipped
        </Button>
      )}
    </>
  );
};

export default OrderDetailPage;
