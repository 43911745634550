import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import ProductsForm from "./ProductsForm";
import { useQuery } from "@tanstack/react-query";
import { getProduct } from "../../requests/product";

const ProductsUpdate = ({ match }) => {
  const { id } = useParams();

  const { data } = useQuery({ queryKey: ["product", Number(id)], queryFn: () => getProduct(id) });

  const onSubmit = async (values) => {
    const payload = { ...values };

    // check for start and end date
    payload.dateStart = payload.productDates[0];
    payload.dateEnd = payload.productDates[1];

    // submit request to backend
    try {
      const response = await axios.patch(`/product/${id}`, payload);
      if (response.status >= 400) {
        let errors;

        if (response.data.error) {
          errors = <span>{response.data.message}</span>;
        }

        if (response.data.errors) {
          errors = (
            <>
              {response.data.errors.map((error) => (
                <span key={error.field}>
                  {`${error.field}: ${error.message}`}
                  <br />
                </span>
              ))}
            </>
          );
        }

        message.error({
          className: "message__error",
          content: errors,
          duration: 8,
        });
      } else {
        message.success({
          className: "message__success",
          content: `Successfully updated product: ${response.data.title}`,
          duration: 8,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {data ? (
        <>
          <section>
            <h1>Update Product</h1>
          </section>

          <ProductsForm onSubmit={onSubmit} product={data} />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default ProductsUpdate;
