import React from 'react';
import useLogin from '../hooks/useLogin';

const Dashboard = () => {
const {user} = useLogin();

  return (
    <section>
      <h1>Dashboard</h1>

      <p>
        Welcome, {user.username}
      </p>
    </section>
  )
};

export default Dashboard;
