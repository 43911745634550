import axios from "axios";
const { REACT_APP_PUBLIC_API_URL } = process.env;

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

api.defaults.baseURL = REACT_APP_PUBLIC_API_URL;

// Add a request interceptor to always add the token to each request
api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
