import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import useLogin from '../hooks/useLogin';

const Logout = () => {

  const { logout } = useLogin();

  useEffect(() => {
    logout()
  },[logout])

  return <Redirect to={`/login`} />;
};

export default Logout;
