import axios from "axios";

export const updateProductState = async (id, productStatus) => {
  try {
    const response = await axios.post(`/product/updateStatus/${id}`, {
      productStatus,
    });
    return response.data;
  } catch (e) {
    console.log(updateProductState.name);
    console.log(e);
  }
};
