import { api } from "./";

export const updateProductToSaleStatus = async (id) => {
  const response = await api.patch(`/product/${id}/sale`);

  return response.data;
};

export const getProduct = async (id) => {
  const response = await api.get(`/product/${id}`);

  return response.data;
};
