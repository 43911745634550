import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import axios from 'axios';
import App from './App';

const {REACT_APP_PUBLIC_API_URL} = process.env;

// override some default behavior of Axios
// axios.defaults.baseURL = REACT_APP_PUBLIC_API_URL + '/mono'
axios.defaults.baseURL = REACT_APP_PUBLIC_API_URL
axios.defaults.validateStatus = (status => {
  return status >= 200 && status < 500; // override default
});

// Add a request interceptor to always add the token to each request
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

ReactDOM.render(
    <Router>
      <App />
    </Router>,
  document.getElementById('root')
);
