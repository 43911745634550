import React, { useState } from "react";
import axios from "axios";

const Account = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    setErrors(false);

    const { data } = await axios.patch("/user", { oldPassword, newPassword });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setOldPassword("");
      setNewPassword("");
      setSuccess(true);
    }
  };

  return (
    <>
      <section className="section">
        <h1 className="section__title">My Account</h1>
      </section>

      <section className="section">
        <h2 className="section__subtitle">Change Password</h2>
        <form className="form">
          <div className="form__field">
            <label htmlFor="password_old" className="form__label">
              Old Password
            </label>
            <input type="password" name="password_old" className="input" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
          </div>

          <div className="form__field">
            <label htmlFor="password_new" className="form__label">
              New Password
            </label>
            <input type="password" name="password_new" className="input" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </div>

          {success && <div className="form__success">Password updated!</div>}

          {errors && (
            <div className="form__error">
              <p>Error: </p>
              {errors.map((error) => {
                return <p key={error.field}>- {error.message}</p>;
              })}
            </div>
          )}

          <button className="form__button" onClick={submitForm}>
            Change Password
          </button>
        </form>
      </section>
    </>
  );
};

export default Account;
