import { useContext, useEffect } from "react";
import { AdminContext } from "../contexts/admin-context";

const useLogin = () => {
  const [state, setState] = useContext(AdminContext);

  const login = (data) => {
    if (data.token) {
      localStorage.setItem("token", data.token);
    }

    setState((currentState) => ({
      ...currentState,
      user: {
        username: data.email || data.username,
        role: data.role,
      },
    }));
  };

  const logout = () => {
    // localStorage.removeItem("token");
    setState((currentState) => ({
      ...currentState,
      user: false,
    }));
  };

  return {
    user: state.user,
    login,
    logout,
  };
};

export default useLogin;
